@import '../theme.scss';

#footerSub {
  background: rgba(18, 18, 18, 0.9);
  text-align: center;
  padding: 0px $standard-padding-x;
  width: 100%;

  > .inner {
    max-width: 1660px;
    padding: 20px 0;

    a,
    p,
    span {
      font-size: 12px;
      color: $color-white;
    }
  }
}
